<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {getHouseApi} from '@/api/houses'

/**
 * House list component
 */
export default {
  page: {
    title: "Houses",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "Houses",
      items: [
        {
          text: "Houses",
          href: "/",
        },
        {
          text: "List",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      searchKey : '',
      fields: [
        
        {
          key: "Developer",
          sortable: true,
        },

        {
          key: "Address",
          sortable: true,
        },
       
      
        {
          key: "Type",
          sortable: true,
        },
        {
          key: "Price",
          sortable: true,
        },
        
        {
          key: "Status",
          sortable: false,
        },
        {
          key: "Action",
          sortable: false,
        },
      
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList()
  },
  mounted() {
  
  },
  methods: {

    queryList() {

      let status_list = ['SUBMITTED','PROCESSING','REJECTED','APPROVED','PENDING_CLOSE']

      let data = {
        status: status_list,
         page : {
          page : this.currentPage,
           page_size: this.perPage
        }
      }
     

     
      getHouseApi().list(data).then((res)=>{
        this.listingData = []
        res.data.map((o) =>{
        
          this.listingData.push({
            id: o.id,
            developers : o.developers,
            unit : o.unit,
            address :o.address,
            price : o.selling_price,
            status : o.order_state,
            city : o.city_name,
            close_date  : o.closing_date,
            deal_type : o.deal_type,
            phone : o.phone,
            type : o.house_type,
            mls : o.mls,
            native_agents : o.native_agents,
            state : o.state,
            sale_state : o.sale_state
          })
          this.totalRows = res.page.total;
         
        })  

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.contact.file = URL.createObjectURL(file);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onEnterSearch() {
      this.queryList();
    },


  },
  validations: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Pending Deal List
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                  <div class="search-box ">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control bg-light border-light rounded"
                        v-model="searchKey"
                        placeholder="Search..."
                        @keyup.enter="onEnterSearch"
                      />
                      <i class="uil uil-search search-icon"></i>
                    </div>
                  </div>

                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="listingData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  class="table-check"
                >

                  <template  #cell(DealId)="data">
                    <router-link
                      :to="{ name : 'deal-overview', query: {deal_id : `${data.item.deal_id}` }}"
                      class="text-body fw-medium"
                      >{{ data.item.deal_id}}</router-link
                    >
                  </template>
                  <template #cell(Developer)="data"> 
                   {{ data.item.developers }}
                  </template>
                  <template #cell(Address)="data"> 
                   {{ data.item.address }}
                  </template>

                  <template #cell(Price)="data">
                    ${{ Number(data.item.price).toLocaleString() }}
                  </template>

                
                  <template #cell(Type)="data">
                    {{ data.item.type }}
                  </template>
                  <template #cell(TrustAmount)>
                   
                  </template>
                  <template #cell(Operator)>
                    
                  </template>
                  <template #cell(Status)="data">
                    {{ data.item.sale_state }}
                  </template>
                  <template #cell(Action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item>Edit</b-dropdown-item>
                      <b-dropdown-item :disabled="data.item.state == 0">Activate</b-dropdown-item>
                      <b-dropdown-item :disabled="data.item.state == 1">DeActivate</b-dropdown-item>
                    </b-dropdown>
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
